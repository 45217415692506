export const BASE = {
  api: {
    base_url: 'https://users.bepass.app',
    // base_url: 'http://localhost:3066',
    user_validate: '/user/validate',
    user_create: '/user',
    validate_register_number: '/multiclubes/user/validate',
    get_user_access: '/milanga/findByUser',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_report: '/visitor/report',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    // ADEMIR
    access_create: '/access',
    access_edit: '/access',
    access_find: '/access',
    access_login: '/access/login',
    access_search: '/access/search',
    hik_get_status: '/hikvision/local/getDeviceInfo',
    find_user_with_biometry: '/biometry/find/game/users',
    find_user_with_biometry_passport: '/biometry/find/passports',
    delete_user: '/user',
    find_user_newc: '/user/newc/document',
    remove_biometry: '/biometry',
    sync_token: '/biometry/forceSync',
    find_all_user_biometrics:'/biometry/find/user/biometrics',
    // access data
    access_approver: '/approvers',
    visitor_company: '/companies',
    visitory_type: '/visitor-type',
    access_reports: '/access-control/report',
    access_report_entrance: '/access/status',
    access_report_allusers: '/access/allusers',
    log_create: '/logs',
    get_log_from_gate: '/access-control/today',
    get_all_logs_for_the_day: '/access-control/monitoring',
    get_passports: '/biometry/passport',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  api_user: {
    base_url: 'https://users.bepass.app',
    user_validate: '/user/validate',
    user_create: '/user',
    user_edit: '/user',
    validate_register_number: '/multiclubes/user/validate',
    validate_entrance: '/multiclubes/entrance/validate/accessCode',
    send_face: '/biometry',
    visitor_create: '/visitor',
    visitor_update: '/visitor',
    visitor_find_by_document: '/biometry/find/visitors',
    access_create: '/access/create',
    access_edit: '/access/edit',
    access_get: '/access',
    log_create: '/logs',
    valid_mask: '/biometry/validate/mask',
    valid_glasses: '/biometry/validate/glasses',
    create_face: '/biometry/create',
    remove_background: '/biometry/removeBackground',
    sync_token: '/biometry/forceSync',
    visitor_force_sync: '/biometry/visitor/forceSync',
    // ADEMIR
    hik_get_cards: '/hikvision/status/card',
    find_user_with_biometry: '/biometry/find/game/users',
    remove_biometry: '/biometry',
    get_passport_count: '/user/passport',
    force_update: '/biometry/internal/forceApproval',
    internal_biometry_count: '/biometry/internal/userCount',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',
    token:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJsb2NhbCIsInVzZXJFbWFpbCI6InRlc3RlOUBhbmFsaXN0YS5jb20iLCJ1c2VySWQiOjEyMSwidXNlclJvbGUiOjEsInVzZXJEZWFsZXIiOjI3LCJkZWFsZXJaaXBDb2RlIjoiNzY5NjMtNzQ5Iiwia2lsbCI6MTYyNzM4NDEwMTI1MiwiaWF0IjoxNjI3Mzg0MTAxfQ.X7oGIOFKZDbfjO6BnlVkMA_y1aik8YwBV-Y8Liu0CvU'
  },
  api_game: {
    base_url: 'https://tickets.bepass.app',
    game_report: '/game-report/auto',
    game_sync_report: '/game-report/get-synced-images',
    get_game_tickets_count: '/tickets/count',
    get_games: '/game',
    active_game: '/game/active',
    game_distribution_by_game: '/distribution/game',
    game_distribution: '/distribution',
    ticket_audit: '/sync/audit',
    tickets: '/tickets',
    get_game_config: '/game/config/company',
    set_game_config: '/game/config',
    terminals: '/terminals',
    api_key:
      'EBWEOLQZTGLJNUYOTDUJVFEVJCGUROOWUSQUOEEHBHGPYJAPSIPQUDQZWAUMEWHESOOUDEAOYNIOYOXABRBJYXCQBRXNKSCFZBXJUFLOIBRGVCAIDUKMVYGVLXSL',

  },
  api_report: {
    base_url: 'https://tickets.bepass.app',
    report_access: '/access/report',
    access_by_type: '/access/monitor/type',
    access_total: '/access/monitor'
  },
  company_data: {
    name: 'botafogo',
    id: '6e7fc4d2-2b5e-48b9-acad-da23b5011b26',
    tenant: 'botafogo-lk5fp',
    applicationId: 3,
    logo: 'https://storage.googleapis.com/companies-assets/botafogo/client_logo.58472699ee7531a3cacb.png',
    onboardingUrl: 'https://botafogo.bepass.com.br'
  }
}
